.crew_container {
    width: 75%;
    padding:40px;
    margin:0 auto;
    margin-top: 50px;
    border-radius: 50px;
    color: #fff;
    line-height: 2;
    display: flex;
    justify-content: space-between;
    background-color: #505780;
    h1 {
        line-height: 1;
        margin-left: 1rem;
    }
    p {
        font-size: 20px;
        letter-spacing: 2px;
    }
    .text {
        width: 100%;
    }
    .member_card_container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        text-align: center;
        line-height: 1.2;
        width: 100%;
        flex:1;
        .member_card {
            .member_image {
                width: 100px;
                height: 100px;
                border-radius: 60px;
                
            }
        }
    }
    @media screen and (max-width: 768px) {
        padding: 30px;
        box-sizing: border-box;
        width:90%;
        border-radius: 25px;
        h1 {
            font-size: 30px;
        }
        p {
            font-size: 15px;
            letter-spacing: 1px;
            margin: 20px;
        }
        
    }
}