.footer_container {
    width: 75%;
    padding:40px;
    margin:0 auto;
    color: #fff;
    margin-top: 50px;
    border-radius: 50px 50px 0px 0px;
    line-height: 2;
    display: block;
    justify-content: center;
    align-items: center;
    background-color: #505780;
    display: flex;
    p {
        font-size: 0.7vw;
        letter-spacing: 2px;
        margin: 30px;
    }  




    @media screen and (max-width: 768px) {
        padding: 10px;
        width:90%;
        border-radius: 25px 25px 0px 0px;
        h1 {
            font-size: 30px;
        }
        p {
            font-size: 1.2vw;
            letter-spacing: 1px;
            margin: 20px;
        }
        
    }
}