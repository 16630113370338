.directors_container {
  width: 75%;
  padding: 40px;
  margin: 0 auto;
  margin-top: 50px;
  border-radius: 50px;
  color: #fff;
  line-height: 2;
  display: flex;
  justify-content: space-between;
  background-color: #505780;
  h1 {
    line-height: 1;
    margin-left: 1rem;
  }
  p {
    font-size: 20px;
    letter-spacing: 2px;
  }
  .text {
    width: 100%;
  }
  .director_card_container {
    display: flex;
    overflow-x: auto;
    flex-direction: row;
  }
  .director {
    flex-direction: row;
    align-items: center;
    text-align: center;
    line-height: 1.2;
    width: 300px;
    min-width: 300px;
    padding: 20px;
    background-color: #656D9E ;
    border-radius: 25px;
    margin: {
      left: 10px;
      right: 10px;
      top: 20px;
      bottom: 20px;
    }
    img {
      width: 150px;
      height: 150px;
      border-radius: 60px;
      object-fit: cover;
    }
    sub {
        font-size: 20px;
        letter-spacing: 1px;

    }
    .director_info {
      h2 {
        font-size: 30px;
        margin-bottom: 10px;
      }
      p {
        font-size: 14px;
        letter-spacing: 1px;
      }
    }
    @media screen and (max-width: 768px) {
      width: 230px;
      min-width: 230px;
      h2 {
        font-size: 15px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    padding: {
        top: 40px;
        left: 10px;
        right: 10px;
        bottom: 30px;
      }
    box-sizing: border-box;
    width: 90%;
    border-radius: 25px;
    h1 {
      font-size: 30px;
    }
    p {
      font-size: 15px;
      letter-spacing: 1px;
    }
  }

  @media screen and (min-width:769px) {
    .director_card_container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: {
        left:50px;
        right:50px;
      };
      .director {
        padding: {
          top:30px;
          bottom:70px;
        };
        p {
          font-size: 17px;
        }
      }
    }
  }
}
